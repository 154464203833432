$color_1: #333;
$color_2: #777;
$color_3: #fff;
$color_4: #007bff;
$color_5: #f5ec50;
$background-color_1: #fff;
$background-color_2: #007bff;
$background-color_3: #f5ec50;
$border-color_1: #007bff;
$dark_green: #89d6ce;

/* Responsive */
.login-container {
	background-color: $background-color_1;
	border-radius: 10px;
	box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
	padding: 25px;
	max-width: 450px;
	width: 90%;
	text-align: center;
	margin: 0 auto;

	@media($breckpoint_SM) {
		padding: 15px;
	}

	@media($breckpoint_ESM) {
		padding: 10px;
	}
}

.login-form {
	display: flex;
	flex-direction: column;

	h1 {
		margin-bottom: 10px;
		color: $color_1;
	}

	p {
		margin-bottom: 20px;
		color: $color_2;
	}

	.btn_flex {
		display: flex;
		gap: 10px;
		position: relative;

		.PhoneInput {
			width: 100%;
			border: 1px solid #ddd;
			border-radius: 8px;

			.PhoneInputInput {
				padding: 8px;
				border: none;
				border-radius: 0 8px 8px 0;
				border-left: 1px solid #ddd;
			}
		}

		.mobilenumber {
			position: absolute;
			width: 73%;
			left: 95px;
			bottom: 1px;
			padding: 3px 10px 3px 20px;
			border: none;
			letter-spacing: .5px;
			height: 44px;
			font-size: 15px;

			@media($breckpoint_SM) {
				width: 71%;
			}

			@media($breckpoint_450) {
				width: 69%;
			}

			@media($breckpoint_ESM) {
				width: 63%;
			}

			&:focus {
				border: none;
				outline: none;
				box-shadow: none;
			}
		}

		.react-tel-input {
			&::before {
				content: "";
				width: 2px;
				height: 26px;
				position: absolute;
				left: 102px;
				top: 9px;
				background-color: #cacaca;
				z-index: 1;
			}

			.special-label {
				display: none;
			}

			input {
				padding: 6px 10px 7px 51px;
				height: 46px;
				width: 100%;
				font-size: 15px;
				color: #000;

				&:focus {
					box-shadow: none;
					border-color: #CACACA;
				}
			}

			.flag-dropdown {
				border: 1px solid transparent;
				background-color: transparent;
				border-right-color: #CACACA;

				&:focus {
					box-shadow: none;
				}

				.selected-flag {
					width: auto;

					&:hover {
						background-color: transparent;
					}
				}

				.country-list {
					height: 188px !important;

					.country {
						text-align: start;
					}

					.search {
						padding: 10px 20px 6px 10px;
						text-align: start;

						.search-box {
							margin: 0;
							padding: 7px 10px 7px 12px;
							height: 39px;
						}
					}
				}
			}
		}


	}
}

.input_width {
	width: 100;
	display: flex !important;
	justify-content: space-between;
	align-items: center;

	input {
		border-radius: 8px !important;
	}
}

.input-group {
	margin-bottom: 20px;

	input {
		padding: 10px;
		border-radius: 8px;
		border: 1px solid #ddd;
		width: 100%;
		font-size: 16px;
		transition: border-color 0.3s ease;

		&:focus {
			border-color: $dark_green;
			outline: none;
		}

		&:focus-visible {
			box-shadow: none;
		}
	}

	.form-select {
		padding: 10px;
		border-radius: 8px;
	}
}

.button_form {
	padding: 15px;
	border: none;
	border-radius: 8px;
	background-color: $background-color_2;
	color: $color_3;
	font-size: 16px;
	cursor: pointer;
	transition: background-color 0.3s ease;

	&:hover {
		background-color: $background-color_3;
	}
}

.bottom-text {
	.bottom_link {
		margin-bottom: 10px;
		display: flex;
		justify-content: flex-start;
		align-items: center;
		font-size: 14px;
		gap: 5px;

		@media($breckpoint_SM) {
			display: block;
			text-align: center;
			font-size: 15px;
		}

		.flex_link {
			display: flex;

			@media($breckpoint_SM) {
				justify-content: center;
			}
		}

		.link_color {
			font-size: 12px;
			color: $color_4;
			text-decoration: none;
			transition: color 0.3s ease;
			margin-bottom: 0;
			cursor: pointer;
			-webkit-user-select: none;
			-ms-user-select: none;
			user-select: none;

			@media($breckpoint_ESM) {
				font-size: 12px;
			}

			&:hover {
				color: #000;
			}
		}
	}
}

@media screen and (max-width: 600px) {
	.login-container {
		width: 100%;
		border-radius: 0;
	}
}

.textarea_profile {
	border-radius: 8px;
	padding: 10px;
	border-color: rgb(206, 212, 218);
	width: 100%;

	&:focus-visible {
		outline: none;
	}

	&:focus {
		box-shadow: none;
	}
}