.calender {
  .react-calendar {
    .react-calendar__navigation {
      button {
        border: 1px solid lightgrey;
        padding: 5px 8px;
        background: none;
        text-align: center;
        margin: 10px 0px 15px 0px;
      }
    }

    .react-calendar__viewContainer {
      .react-calendar__month-view__weekdays {
        .react-calendar__month-view__weekdays__weekday {
          text-align: center;
        }

        abbr {
          text-decoration: none;
        }
      }

      .react-calendar__month-view__days {
        button {
          border: 1px solid lightgray;
          padding: 13px;
        }

        p {
          display: none !important;
        }
      }
    }
  }

  .highlighted-date {
    background-color: $primary-color;
  }

  .default-highlighted-date {
    background-color: $secondry-color;
  }
}

.selectedates {
  gap: 5px;
}

.btn_hours {
  display: flex;
  justify-content: center;
  padding: 0px 20px;

  .btn_number {
    padding: 0px;
  }

  button {
    background: rgb(235 234 234);
    width: 35px;
    border-radius: 4px;
    padding: 5px;
    border: none;
    box-shadow: 0px 0px 11px -5px rgb(126 124 124 / 75%);
    margin: 5px 0px;
  }
}

.change_time {
  .time_change_input {
    display: flex !important;
    justify-content: space-between !important;

    .time_select {
      display: flex;
      align-items: center;
      font-size: 19px;
      font-weight: 500;
    }

    .apply_btn {
      border: none;
      border-radius: 7px;
      background-color: $primary-color;
      color: $light-color;
      padding: 8px 15px;
      font-size: 19px;
      font-weight: 500;
    }
  }
}

.updates {
  display: flex;
  justify-content: center;
  position: absolute;
  right: 21px;
  top: 17px;

  .delete_btn_red {
    background-color: red !important;
  }

  .update_btn {
    background: #89d6ce !important;
    border: none;
    border-radius: 7px;
    font-weight: 500;
    padding: 5px 20px;
    color: #fff;
  }
}