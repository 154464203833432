@import '../../assets//sass/color.scss';
@import '../../assets//sass/fonts.scss';
@import '../../assets//sass/mixin.scss';

.about_nanny_text {
    .line_text {
        @media($breckpoint_LG) {
            font-size: 55px;
        }

        @media($breckpoint_MD) {
            font-size: 47px;
        }

        @media($breckpoint_SM) {
            font-size: 39px;
        }

        @media($breckpoint_ESM) {
            font-size: 32px;
        }
    }
}

.tutor_info {
    flex: 0 0 40%;

    @include media-md {
        flex: 0 0 100%;
    }

    @media($breckpoint_MD) {
        padding-top: 25px !important;
        padding-bottom: 0 !important;
    }
}

.tutor_solution {
    @media($breckpoint_MD) {
        padding-top: 0 !important;
    }
}

.tutoring_inner_sec {
    margin-top: 0 !important;
}

.babysitter {
    font-size: 44px;
    font-weight: 600;

    @media($breckpoint_LG) {
        font-size: 33px;
    }

    @media($breckpoint_MD) {
        font-size: 29px;
    }

    @media($breckpoint_SM) {
        font-size: 24px;
    }
}

.tutor_image {
    flex: 0 0 50%;
    background-image: url(../../assets/img/banner_bg.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    z-index: 2;

    &::after {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        content: '';
        background-color: rgba($dark-color, .5);
        z-index: -1;
    }

    h1 {
        color: $light-color;
    }

    @include media-md {
        flex: 0 0 100%;
    }
}

.tutor_form {
    @media($breckpoint_MD) {
        padding-top: 0 !important;
    }

    .tutor_info {
        flex: 0 0 40%;

        @include media-md {
            flex: 0 0 100%;
        }
    }

    .tutor_image {
        flex: 0 0 60%;

        @include media-md {
            flex: 0 0 100%;
        }

        background-image: url(../../assets/img/banner_bg.jpg);
        background-repeat: no-repeat;
        background-size: cover;
        z-index: 2;

        @media($breckpoint_MD) {
            display: none !important;
        }

        &::after {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            content: '';
            background-color: rgba($dark-color, .5);
            z-index: -1;
        }
    }

    .tutor_form_field {
        span {
            color: #ff0000;
        }
    }
}