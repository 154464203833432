@import '../../assets/sass/color.scss';
@import '../../assets/sass/mixin.scss';
@import url('https://fonts.googleapis.com/css2?family=Kanit:wght@300&family=Merriweather:wght@700&family=Roboto&display=swap');

@mixin display($justify-content, $align-items) {
    display: flex;
    justify-content: $justify-content;
    align-items: $align-items;
}

.banner_bg_home {
    width: 100%;
    height: 100vh;
    background-repeat: no-repeat;
    background-size: 100% !important;
    background: url('../../assets/img/banner_bg.jpg');
    z-index: -1;
    background-position: center;
    background-attachment: fixed;

    @include media-md {
        background-size: cover !important;
    }

    @media($breckpoint_LG) {
        height: 98vh;
    }

    @media($breckpoint_MD) {
        height: 60vh;
    }

    &::after {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        content: '';
        background-color: rgba(137, 214, 206, .8);
    }
}

.banner_bg_download {
    width: 100%;
    height: 100vh;
    background-repeat: no-repeat;
    background-size: 100% !important;
    background: url('../../assets/img/banner_bg.jpg');
    z-index: -1;
    background-position: center;
    background-attachment: fixed;

    @include media-md {
        background-size: cover !important;
    }

    &::after {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        content: '';
        background-color: rgba(137, 214, 206, .8);
    }
}

.banner_download {
    //height: calc(100vh - 146px);
    height: 100vh;

    @media($breckpoint_XXL) {
        padding-top: 100px;
    }

    @media($breckpoint_MD) {
        text-align: center;
        height: 100%;
        padding-top: 80px;
    }

    h2 {
        font-size: 55px;
        color: $light-color;
        font-weight: 700;

        @media($breckpoint_LG) {
            font-size: 40px;
        }

        @media($breckpoint_MD) {
            font-size: 35px;
        }

        @media($breckpoint_SM) {
            font-size: 30px;
        }

        @media($breckpoint_ESM) {
            font-size: 27px;
        }
    }

    p {
        color: $light-color;
        font-size: 23px;
        font-weight: 300;
        line-height: 1.2;
        letter-spacing: 0.5px;

        @media($breckpoint_LG) {
            font-size: 19px;
        }

        @media($breckpoint_SM) {
            font-size: 16px;
        }

        @media($breckpoint_ESM) {
            font-size: 15px;
        }
    }

    .btn-book {
        background-color: $secondry-color !important;
        padding: 10px 50px;
        border-radius: 10px;

        @media($breckpoint_MD) {
            margin-top: 0 !important;
            padding: 8px 35px;
            border-radius: 6px;
        }
    }

    .android_ios_btn {
        display: flex;
        flex-direction: column;
        margin-bottom: 25px;

        @media($breckpoint_MD) {
            gap: 10px;
        }
    }

    .mobile_app_img {
        @media($breckpoint_MD) {
            display: none;
        }
    }
}

.baner_text_home {
    //height: calc(100vh - 146px);
    height: 100vh;

    @media($breckpoint_LG) {
        height: 95vh;
    }

    @media($breckpoint_MD) {
        height: 60vh;
    }

    .download_head {
        font-size: 55px;
        font-weight: 600;
        color: $light-color;

        @media($breckpoint_LG) {
            font-size: 40px;
        }

        @media($breckpoint_MD) {
            font-size: 35px;
        }

        @media($breckpoint_SM) {
            font-size: 28px;
        }
    }

    h2 {
        font-size: 25px;
        color: $light-color;
        font-weight: 700;
        // font-family: 'Merriweather', serif;
    }

    h1 {
        font-size: 80px;
        font-weight: 800;
        color: $light-color;
        // font-family: 'Merriweather', serif;

        @include media-md {
            font-size: 40px;
        }

        span {
            font-weight: 700;
        }
    }

    p {
        color: $light-color;
        font-size: 23px;
        font-weight: 300;
        line-height: 1.2;
        letter-spacing: 0.5px;

        @media($breckpoint_SM) {
            font-size: 18px;
        }
    }

    .btn-book {
        background-color: $secondry-color !important;
        padding: 10px 50px;
        border-radius: 10px;

        @media($breckpoint_MD) {
            margin-top: 0 !important;
            padding: 8px 35px;
            border-radius: 6px;
        }
    }

    .android_ios_btn {
        display: flex;
        flex-direction: column;
    }
}

.who_we_section {
    h2 {
        font-size: 27px;
    }

    h1 {
        font-size: 37px;
    }

    .babysitter {
        @media($breckpoint_SM) {
            font-size: 23px;
        }
    }

    // .secound_section {
    //     padding: 25px 15px;

    //     @media($breckpoint_SM) {
    //         padding: 15px 10px;
    //     }
    // }
}

.services_box {
    .offer_nanny {
        font-size: 37px;

        @media($breckpoint_SM) {
            font-size: 33px;
        }

        @media($breckpoint_ESM) {
            font-size: 30px;
        }
    }

    .service_arrow {
        padding: 25px;
        cursor: pointer;
        background-color: $light-color;
        border-radius: 60px 60px 60px 60px;
        box-shadow: 0px 0px 19px 0px rgba(0, 0, 0, 0.17);
        margin-left: -100px;

        @include media-md {
            margin-left: 0;
        }

        @media($breckpoint_XL) {
            padding: 17px;
        }

        @media($breckpoint_LG) {
            padding: 13px;
        }

        @media($breckpoint_MD) {
            padding: 25px;
        }

        @media($breckpoint_SM) {
            padding: 15px;
        }

        svg {
            padding: 3px;
            width: 30px;
            height: 30px;
            background-color: $primary-color;
            color: $light-color;
            @include border-radius(50px);
        }
    }
}

.number_say_box {
    border: 1px solid rgba($dark-color, .1);

    h2 {
        font-size: 50px;
        color: $primary-color;
    }
}

.choose_icon {
    margin-top: -50px;

    svg {
        font-size: 60px;
        color: $secondry-color;
    }
}

.why_2 {
    margin-top: 60px;
}

.why_3 {
    margin-top: 120px;
}

.why_4 {
    margin-top: 180px;
}

.testimonials_box {
    background-color: $testimonial_bg;

    @media($breckpoint_MD) {
        margin-top: 15px;
    }

    .stars {
        gap: 5px;

        svg {
            color: $secondry-color;
        }
    }
}

.testimonials {
    .customer_style {
        width: 50%;
        text-align: center;

        @media($breckpoint_MD) {
            width: 100%;
        }
    }
}

.prtners {
    .wlogo_img {
        border: 1px solid #dcc8c8;
        border-radius: 10px;
        padding: 5px;

        @media($breckpoint_SM) {
            padding: 0;
        }
    }
}

.filter_star {
    svg {
        color: #f5ec50;
    }
}


//text-overflow
.ellipsis {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}