// p2p modal

@keyframes customEnterModalAnimation {
    0% {
        transform: translateX(300px);
    }

    100% {
        transform: translateX(0px);
    }
}


@keyframes customLeaveModalAnimation {
    0% {
        transform: translateX(0px);
    }

    100% {
        transform: translateX(300px);
    }
}


.P2P-ID__data {
    height: 100% !important;
    margin: 0 !important;
    // max-width: 100% !important;
    position: fixed !important;
    right: 0 !important;
    top: 0 !important;
    // width: 500px !important;
    border-radius: 0px !important;
    background-color: #fff !important;
    z-index: 9999;
    padding: 0px !important;


    .react-responsive-modal-closeButton {
        position: absolute;
        right: 12px;
        background: transparent;
        border: none;
        border-radius: 33px;
        padding: 0px;
        font-size: 2px;
        top: 12px;
        filter: invert(1);

        svg {
            @media($breckpoint_LG) {
                filter: invert(1);
            }
        }

    }

    .main-sec {
        margin-top: 40px;

        .btn {
            background-color: transparent !important;
            border-radius: 10px;
            margin: 0px 10px;
            display: flex;
            justify-content: center;
            align-items: center;

            .home_span_flex {
                .login_btns {
                    background-color: $primary-color !important;
                    padding: 6px 15px;
                    border-radius: 4px;
                    color: #fff !important;
                    border: none;
                }
            }
        }


        .inner-sec {

            .menu-list2 {
                display: grid;
                justify-content: center;
                align-items: center;
                padding: 0px;
                line-height: 3rem;
                margin: 20px 0px;

                // .head_sec_top {
                //     border-top: 1px dashed #bcbbbb54;
                // }

                a {
                    text-decoration: none;
                    font-size: 18px;
                    width: 350px;
                    border-bottom: 1px dashed #bcbbbb54;
                    padding: 0px 15px;
                    text-align: center;
                    color: #000;

                    @media($breckpoint_SM) {
                        width: 300px;
                    }

                    &:focus {

                        outline: none;
                    }
                }
            }
        }

        .span_flex {
            .logout_btn {
                @media($breckpoint_LG) {
                    background-color: $primary-color !important;
                    padding: 6px 15px;
                    border-radius: 4px;
                    color: #fff !important;
                    border: none;
                }
            }

            .btn {
                display: flex;
                justify-content: space-around;
                align-items: center;
                // background-color: transparent !important;
                margin-top: 25px;
                text-align: center;

                @media($breckpoint_LG) {
                    gap: 10px;
                }

                a {
                    text-decoration: none;
                    background-color: #89d6ce;
                    padding: 9px 23px;
                    border-radius: 5px;
                    color: #000;
                }

                .main-button {
                    padding: 9px 30px;
                }
            }
        }

        .notifications {

            .nav {
                list-style: none;

                li {
                    a {
                        background-color: $primary-color;
                        text-decoration: none;
                        color: $light-color;
                        padding: 10px 15px;
                        font-size: 14px;
                        font-weight: 600;
                        font-family: "Sofia Sans", sans-serif;
                        transition: all .4s linear;
                        border-radius: 7px;

                        @media($breckpoint_ESM) {
                            font-size: 15px;
                        }

                        &:hover {
                            // background-color: $header-bg-hover;
                            color: $secondry-color;

                            .arrow_icon {
                                transform: rotate(180deg);
                            }
                        }
                    }
                }
            }

            .hover_content_public {
                display: none;
                position: absolute;
                background: $services_bg;
                min-width: 250px;
                border-radius: 0px 0px 5px 5px;
                transition: all 0.5s linear;
                animation: rotateMenu 600ms ease-in-out forwards;
                transform-origin: top center;
                z-index: 2;
                border-top: none !important;
                border: 1px solid $light-color;
                top: 51px;
                right: 10px;

                @media($breckpoint_XL) {
                    top: 53px;
                }

                &:hover {
                    .arrow_icon {
                        transform: rotate(180deg);
                    }

                    .nav {
                        li {
                            a {
                                background-color: red;
                            }
                        }
                    }
                }

                .drop_downcont {
                    ul {
                        padding: 10px;
                        list-style: none;
                        width: 300px;

                        .hover_a {
                            padding: 0px;
                            transition: all .3s linear;

                            .item_flex {
                                display: flex;
                                justify-content: space-between;
                                align-items: center;
                                border-radius: 4px;
                                margin: 12px 0px;
                                padding: 8px 8px;
                                background-color: transparent;
                                transition: all .3s linear;

                                .icon_gap {
                                    display: flex;
                                    gap: 15px;

                                    span {
                                        font-size: 14px;
                                        font-weight: 500;
                                        color: #000;
                                    }

                                    p {
                                        margin-bottom: 0px;
                                        color: #000;
                                        font-size: 12px;
                                        font-weight: 400;
                                    }

                                    svg {
                                        color: $primary-color;
                                        font-size: 22px;

                                        @media($breckpoint_ESM) {
                                            font-size: 18px;
                                        }
                                    }
                                }

                                .forward_icon {
                                    color: transparent;
                                    font-size: 18px;
                                    display: block;
                                    transition: all .3s linear;
                                }
                            }

                            &:hover {
                                .item_flex {
                                    background-color: $secondry-color;
                                    transition: all .3s linear;
                                }

                                .forward_icon {
                                    transition: all .3s linear;
                                    color: $primary-color;
                                }
                            }
                        }
                    }
                }
            }

            .list_item {
                &:hover {
                    .hover_content_public {
                        display: block;
                    }

                    .arrow_icon {
                        transform: rotate(180deg);
                    }
                }
            }
        }

        .theme_switch {
            display: flex;
            justify-content: center;
            margin-top: 20px;
        }
    }
}