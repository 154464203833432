/* You can add global styles to this file, and also import other style files */
@import './assets/sass/color.scss';

@import './assets/sass/fonts.scss';

@import './assets/sass/mixin.scss';

@import '../node_modules/bootstrap/dist/css/bootstrap.css';

// @import "~@angular/material/prebuilt-themes/indigo-pink.css";

@import '../src/component/header/header.scss';

@import '../src/component/header/modal.scss';

@import "../src/component/about/about.scss";

@import "../src/component/footer/footer.scss";

@import "../src/component/blogs/blogs.scss";

@import "../src/component/Contact/contact.scss";

@import "../src/component/Home/home.scss";

@import "../src/component/Services/services.scss";

@import "../src/component/auth/login/Login.scss";

@import "../src/component/events/Events.scss";

@import "../src/component/tutorings/tutorings.scss";

@import "../src/component/my-orders/my_orders_component.scss";

@import "../src/component/profile/profile.scss";

@import "../src/component/nany-listing/Nanylisting.scss";

@import "../src/component/CustomModal/CustomModal.scss";

@import "../src/component/Redeempromotion/Bookdetails.scss";

@import "../src/component/auth/Signupnanny.scss";

@import "./component/Nanny-categories/Nannycategories.scss";

@import "./component/auth/Forgotpassword/Forgotpassword.scss";

@import "./component/Availability/Availability.scss";

@import './component//stripe/checkoutForm.scss';

@import './component/PrivacyPolicy/privacyPolicy.scss';

$breakpoint_XXXL: "max-width:1700px";
$breckpoint_XXL: "max-width: 1400px";
$breckpoint_XL: "max-width: 1200px";
$breckpoint_LG: "max-width: 992px";
$breckpoint_MD: "max-width: 768px";
$breckpoint_SM: "max-width: 576px";
$breakpoint_450: "max-width: 450px";
$breckpoint_ESM: "max-width: 385px";
$breckpoint_340: "max-width: 355px";


.coustom_container {
  max-width: 1600px;
  width: 100%;
  margin: 0 auto;
  padding: 0 100px;

  @media ($breckpoint_XL) {
    padding: 0 40px;
  }

  @media ($breckpoint_LG) {
    padding: 0 30px;
  }

  @media ($breckpoint_MD) {
    padding: 0 18px;
  }

  @media ($breckpoint_SM) {
    padding: 0 15px;
  }
}

::-webkit-scrollbar {
  width: 0px !important;
}

*,
h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6,
p,
ol,
ul,
li,
hr {
  margin: 0;
  padding: 0;
}

ul,
ol,
li {
  list-style: none;
}

a,
a:hover {
  text-decoration: none;
}

.secondry_bg {
  background-color: $services_bg;
}

//.all_btns {
//  background-color: #6EC1E4;
//  border: 0;
//  overflow: hidden;
//  border-radius: 50px;
//  -webkit-transition: all ease-in-out 500ms;
//  transition: all ease-in-out 500ms;

//  &::before {
//    content: '';
//    position: absolute;
//    top: 0;
//    border-radius: 50% 0 0 50%;
//    left: 0;
//    width: 0;
//    height: 100%;
//    z-index: -1;
//    background-color: #f5ec50;
//    -webkit-transition: width ease-in-out 500ms;
//    transition: width ease-in-out 500ms;
//  }

//  &::after {
//    content: '';
//    position: absolute;
//    top: 0;
//    border-radius: 0 50% 50% 0;
//    right: 0;
//    width: 0;
//    height: 100%;
//    z-index: -1;
//    background-color: #f5ec50;
//    -webkit-transition: width ease-in-out 500ms;
//    transition: width ease-in-out 500ms;
//  }

//  &:hover {
//    background-color: #f5ec50;
//    color: #000;
//    -webkit-transition: all ease-in-out 500ms;
//    transition: all ease-in-out 500ms;
//  }

//  &:hover::before {
//    width: 50%;
//    -webkit-transition: width ease-in-out 500ms;
//    transition: width ease-in-out 500ms;
//  }

//  &:hover::after {
//    width: 50%;
//    -webkit-transition: width ease-in-out 500ms;
//    transition: width ease-in-out 500ms;
//  }
//}

.sections_padding {
  padding-top: 30px;
  padding-bottom: 30px;

  @include media-md {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  h1 {
    font-weight: 700;

    span {
      &::after {
        position: absolute;
        content: '';
        bottom: -20px;
        left: calc(50% - 100px);
        width: 200px;
        height: 5px;
        background-color: $primary-color;
        @include border-radius(5px);
      }
    }
  }
}

.inner_pages_banner {
  width: 100%;
  height: 100vh;
  padding-top: 200px;
  padding-bottom: 140px;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  background: url('assets/img/banner_bg.jpg');
  z-index: -1;
  background-attachment: fixed;
  background-position: bottom;

  @include media-md {
    padding-top: 60px;
    padding-bottom: 60px;
  }

  &::after {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    content: '';
    background-color: rgba(137, 214, 206, .8);
  }
}

.baner_text {
  height: 100vh;

  h1 {
    font-size: 70px;
    font-weight: 700;
    color: $light-color;
  }
}

.contact_bg {
  background-color: $secondry-color;

  @media($breckpoint_SM) {
    background-color: transparent;
  }
}

.contact_bg_color {
  @media($breckpoint_SM) {
    background-color: $secondry-color;
    border-radius: 4px;
  }
}

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.order_card .mat-mdc-tab.mdc-tab {
  background-color: #f1f1f1;
}

.order_card .mat-mdc-tab.mdc-tab.mdc-tab--active {
  background-color: $green-color;
}

.order_card .mat-mdc-tab.mdc-tab.mdc-tab--active .mdc-tab__ripple::before {
  background-color: $green-color;
}

.order_card .mdc-tab__ripple::before {
  background-color: $nany-book-color;
}

.order_card .mat-mdc-tab:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label {
  color: $light-color;
}

.order_card .mat-mdc-tab:hover .mdc-tab__ripple::before {
  opacity: 1;
}

.order_card .mat-mdc-tab:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline {
  border-color: $green-color;
}

.btn {
  border: none !important;
  background: $primary-color !important;

  &:focus {
    box-shadow: none !important;
  }

  &:focus-visible {
    box-shadow: none !important;
  }
}