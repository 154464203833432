@import '../../assets//sass/color.scss';
@import '../../assets//sass/mixin.scss';


.top-padding {
    padding-top: 100px;

    @media($breckpoint_XL) {
        padding-top: 80px;
    }

    @media($breckpoint_LG) {
        padding-top: 70px;
    }

    .card_profile {
        padding: 20px;
    }
}

.profile_photo {
    img {
        width: 80px;
        height: 80px;
        object-fit: cover;
        border-radius: 50%;
    }
}

.card_height {
    height: 630px;
    overflow-y: scroll;

    @media ($breckpoint_LG) {
        height: 100%;
        margin: 10px 0px;
    }
}

.profile_edit_options {
    @media ($breckpoint_LG) {
        display: flex;
        align-items: center;
        gap: 5px;
        overflow-x: scroll;
    }

    .react-tabs__tab--selected {
        background-color: $primary-color;
        color: #fff;
        border-radius: 6px;
    }

    span {
        font-size: 16px;
        font-weight: 500;
    }

    .profile_option {
        margin: 6px;
        border-radius: 6px;
        padding: 10px 5px;
        white-space: nowrap;
        @include transition;
        cursor: pointer;

        @media($breckpoint_LG) {
            padding: 5px 8px;
        }

        &:hover {
            background-color: $secondry-color;
            color: $light-color;
            @include transition;
        }

        &:focus-visible {
            outline: none;
        }
    }
}

.heading_back_btn button {
    border: 0;

    i {
        font-weight: 700;
        color: #000;
    }
}

.my_profile_border {
    border-bottom: 1px solid $services_bg;

    h3 {
        font-size: 16px;
    }
}

.edit_flex {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media($breckpoint_ESM) {
        flex-direction: column;
        justify-content: center;
        gap: 10px;
    }
}

.edit_photo {
    img {
        width: 80px;
        height: 80px;
        border-radius: 50%;
        object-fit: cover;
    }

    .upload_input_text {
        @media($breckpoint_SM) {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 10px;
        }
    }

    .upload-input {

        label {
            cursor: pointer;
        }
    }
}

.change_image_btn {
    font-size: 14px;
    color: $green-color;
}

.add_btn {
    background-color: rgba(110, 193, 228, 0.2);
    color: #6EC1E4;
    font-size: 14px;
    font-weight: 500;
    padding: 5px 10px;
    border-radius: 7px;
}

.profile_button {
    background-color: $primary-color;
    border: none;
    border-radius: 7px;
    color: $light-color;
}

.card_box {
    box-shadow: 0 0 20px rgb(92 92 92 / 10%);
    margin-bottom: 15px;
    border: none;

    h6 {
        font-weight: 400;
        font-size: 16px;
    }

    h5 {
        font-weight: 600;
    }

    .card_icon {
        img {
            max-width: 80px;
        }
    }

    .card_type {
        background-color: rgba($primary-color, .2);
        color: $primary-color;
        font-size: 12px;
        font-weight: 500;
    }

    h5 {
        span {
            font-weight: 600;
        }
    }

    .delete_btn {
        background-color: transparent;
        color: $danger-color;
        border: none;

        &:hover {
            background-color: transparent !important;
        }
    }

    .savebtn {
        background-color: transparent;
        border: 0;
        color: $primary-color;
    }
}

.redeem_btn {
    background-color: $green-color !important;
    color: $dark-color !important;
    height: 56px;
}

.btn-cancel-nany-profile {
    background-color: rgba($danger-color, .1);
    color: $danger-color;
    font-size: 16px;
    font-weight: 500;
    width: auto;
    max-width: 100px;
}

.offer_validity {
    border-top: 1px solid rgba($primary-color, .2);
    font-size: 14px;
    color: #000;

    span {
        font-weight: 600;
    }
}

.credit_bg {
    background: rgb(51, 144, 134);
    background: -moz-linear-gradient(90deg, rgba(51, 144, 134, 1) 0%, rgba(137, 214, 206, 1) 100%);
    background: -webkit-linear-gradient(90deg, rgba(51, 144, 134, 1) 0%, rgba(137, 214, 206, 1) 100%);
    background: linear-gradient(90deg, rgba(51, 144, 134, 1) 0%, rgba(137, 214, 206, 1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#339086", endColorstr="#89d6ce", GradientType=1);
    color: #fff;

    h1 {
        font-weight: 700;
    }
}

.credit_score {
    h5 {
        font-size: 16px;

        span {
            font-weight: 600;
        }
    }
}

.credit_box_main {
    &::after {
        content: '';
        content: "";
        position: absolute;
        bottom: -70px;
        right: -70px;
        background-color: rgba(255, 255, 255, 0.35);
        width: 200px;
        height: 200px;
        border-radius: 130px;
    }
}

.Earning {
    .card_scroll {
        display: flex;
        align-items: center;
        justify-content: space-between;
        overflow-x: scroll;
        white-space: nowrap;

        @media($breckpoint_SM) {
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
        }

        .card {
            text-align: center;
            padding: 10px 5px;
            margin-right: 10px;

            @media($breckpoint_MD) {
                margin-right: 5px;
            }

            @media($breckpoint_SM) {
                width: 280px;
            }

            @media($breckpoint_ESM) {
                width: 240px;
            }

            span {
                color: #b4b4b4;
                margin-bottom: 6px;
            }
        }
    }
}

.manageservices {
    .btn_yes {
        gap: 10px;
        display: flex;
        border-radius: 7px;

        .btn-check {
            &:focus {
                box-shadow: none;
            }
        }
    }
}

.form-switch .form-check-input {

    &:focus {
        box-shadow: none;
        border-color: #89d6ce;
    }

    &:focus-visible {
        outline: none;
    }
}

.form-check-input:checked {
    background-color: #89d6ce;
    border-color: #89d6ce;
}

.doc_nanny {
    .doc_btn {
        padding: 0px 4px;
    }

    .input-group {
        border: none;
        border-bottom: 1px solid #6f9ead;
        padding: 0px 3px;
        margin-top: 10px;

        &:focus {
            border: none;
            border-bottom: 1px solid #6f9ead;
            outline: none;
            box-shadow: none;
        }
    }

    .save-btn {
        background-color: $primary-color;
        padding: 7px 25px;
        border-color: $primary-color;
    }

    .upload-box {
        height: 85px;
        width: 60px;
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        background-color: #f5f6f7;

        .dz-clickable {
            border: 1px dashed #b1becc;
            background: transparent;
            border-radius: 4px;
            height: 100%;
            padding: 50px 0 20px;
            text-align: center;

            .dz-message {
                padding-bottom: 30px;
                position: relative;

                .temprary-input {
                    position: absolute;
                    top: -50px;
                    background-color: transparent;
                    height: 100px;
                    width: 100%;
                    left: 0;
                    opacity: 0;
                }
            }
        }
    }
}

.contactus {
    .number {
        border-bottom: 1px solid #6f9ead;
        padding: 10px 0px;
        align-items: center;

        h6 {
            margin: 0px 5px;
        }

        svg {
            color: $primary-color;
        }
    }

    .email {
        padding: 10px 0px;
        align-items: center;

        h6 {
            margin: 0px 7px;
        }

        svg {
            color: $primary-color;
        }
    }
}

.modal_address {
    top: 60px !important;

    .modal-body {
        form {
            display: grid;

            .address_form {
                display: contents;

                label {
                    margin-bottom: 5px;
                    padding-left: 6px;
                    color: #666666;
                }

                input {
                    border: none;
                    border-bottom: 1px solid #949292;
                    margin-bottom: 8px;
                    padding-left: 6px;
                    font-weight: 600;

                    &:focus-visible {
                        border: none;
                        border-bottom: 1px solid #949292;
                        outline: none;
                        box-shadow: none;
                    }

                    &:focus {
                        border: none;
                        border-bottom: 1px solid #949292;
                        outline: none;
                        box-shadow: none;
                    }
                }
            }
        }
    }

    .modal-footer {
        justify-content: center !important;
        border: none;
        padding-top: 0px;
    }

    .address_btn {
        background-color: $primary-color;
        border-color: $primary-color;
        width: 50%;
    }
}

.main_input {
    margin-bottom: 8px;
    position: relative;

    .input_field_flex {
        label {
            color: #000;
            font-size: 15px;
            font-weight: 500;
            margin-bottom: 5px;
        }

        .btn_flex {
            display: flex;
            gap: 10px;
        }
    }

    .react-tel-input {

        .special-label {
            display: none;
        }

        input {
            padding: 7px 10px 7px 55px;
            height: 46px;
            width: 100%;
            font-size: 15px;
            color: #000;

            &:focus {
                box-shadow: none;
                border-color: #CACACA;
            }
        }

        .flag-dropdown {
            border: 1px solid transparent;
            background-color: transparent;
            border-right-color: #CACACA;

            &:focus {
                box-shadow: none;
            }

            .selected-flag {
                width: auto;

                &:hover {
                    background-color: transparent;
                }
            }

            .country-list {
                height: 188px !important;

                .search {
                    padding: 10px 20px 6px 10px;

                    .search-box {
                        margin: 0;
                        padding: 7px 10px 7px 12px;
                    }
                }
            }
        }
    }

    .mobilenumber {
        position: absolute;
        width: 60%;
        left: 105px;
        font-size: 15px;
        bottom: 8px;
        padding: 4px;
        border: none;

        @media($breckpoint_LG) {
            width: 53%;
        }

        &:focus {
            border: none;
            outline: none;
            box-shadow: none;
        }
    }
}

.form-label {
    font-size: 15px;
}

.save {
    padding: 5px 22px;
}

.form-select {
    &:focus {
        box-shadow: none;
        outline: none;
    }

    &:focus-visible {
        box-shadow: none;
        outline: none;
    }
}

.basic-multi-select {
    width: 100%;
    outline: none;
}