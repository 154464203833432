@import '../../assets/sass/color.scss';
@import '../../assets/sass/fonts.scss';
@import '../../assets/sass/mixin.scss';

.booking_box {
  box-shadow: 0 0 15px rgba(0, 0, 0, .15);
}

.address_box {
  i {
    width: 60px;
    height: 60px;
    background: $services_bg;
    font-size: 20px;
    min-width: 60px;
    border-radius: 50px;
  }
}

.iframe_container {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 50%;
}

.iframe_container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.touch_text {
  @media($breckpoint_XL) {
    font-size: 35px;
    margin-bottom: 20px !important;
  }

  @media($breckpoint_LG) {
    font-size: 28px;
    margin-bottom: 15px !important;
  }

  @media($breckpoint_SM) {
    font-size: 25px;
  }
}

.contact_texts {
  @media($breckpoint_LG) {
    font-size: 55px !important;
  }

  @media($breckpoint_MD) {
    font-size: 47px !important;
  }

  @media($breckpoint_SM) {
    font-size: 39px !important;
  }
}