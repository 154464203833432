$color_1: #475F7B;
$background-color_1: #fff;
$background-color_2: #FFF;
$border-color_1: #5A8DEE;

.form-area {
	background-color: $background-color_1;
	box-shadow: 0px 5px 10px rgba(90, 116, 148, 0.3);
	padding: 40px;
	border-radius: 6px;
	display: flex;
	align-items: center;
	justify-content: center;

	.form-inner {
		width: 100%;
	}
}

.form-control {
	display: block;
	width: 100%;
	height: auto;
	padding: 10px 15px;
	font-size: 1rem;
	line-height: 1.4;
	color: $color_1;
	background-color: $background-color_2;
	border: 1px solid #DFE3E7;
	border-radius: .267rem;
	-webkit-transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
	transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;

	&:focus {
		color: $color_1;
		background-color: $background-color_2;
		border-color: #89d6ce;
		outline: 0;
		box-shadow: 0 3px 8px 0 rgb(0 0 0 / 10%);
	}
}

.intl-tel-input {
	width: 100%;
}

.iti {
	width: 100%;
}