//breckpoints
$breckpoint_XXXL: "max-width:1700px";
$breckpoint_XXL: "max-width: 1400px";
$breckpoint_XL: "max-width: 1200px";
$breckpoint_LG: "max-width: 992px";
$breckpoint_MD: "max-width: 768px";
$breckpoint_SM: "max-width: 576px";
$breckpoint_450: "max-width: 450px";
$breckpoint_ESM: "max-width: 385px";
$breckpoint_340: "max-width: 355px";

.main-head {
  // background-color: rgba(255, 255, 255, 0);
  background-color: #fff;
  padding: 10px 0px;
  border-bottom: 1px solid #ffffff1f;
  position: fixed;
  z-index: 9999;
  width: 100%;
  color: #000 !important;

  @media(max-width:900px) {
    display: none;
  }

  &.scrolled {
    //background-color: #005ed3 !important; /* Change background color when scrolled */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    transition: .5s;
    border-color: transparent !important;
  }

  .inner {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .logo_header {
      .logo_width {
        width: 160px;

        @media($breckpoint_SM) {
          width: 140px;
        }

        @media($breckpoint_450) {
          width: 130px;
        }

        @media($breckpoint_ESM) {
          width: 120px;
        }
      }
    }

    .logo {
      p {

        color: #000;
        font-weight: bold;
        margin-bottom: 0px;
      }

      a {

        text-decoration: none;
      }
    }

    .navigation {
      ul {
        display: flex;
        list-style: none;
        gap: 30px;
        margin-bottom: 0px;

        @media($breckpoint_XXL) {
          gap: 4px;
        }

        li {
          a {
            text-decoration: none;
            color: #000;
            transition: .5s;
            padding: 15px;
            border-radius: 5px;

            @media($breckpoint_XL) {
              padding: 10px;
            }

            &:hover {
              color: #89d6ce;
            }

            &:active {
              color: #89d6ce;
            }
          }
        }
      }
    }



    .btns {
      display: flex;
      align-items: center;

      .btn {
        background-color: $primary-color;
        border-radius: 7px;
        margin: 0px 10px;

        @media($breckpoint_ESM) {
          margin: 0 0 0 5px;
        }

        .main-button {
          color: #fff !important;
          background: transparent;
          border: none;
        }
      }

      .logout_btn_header {
        @media($breckpoint_SM) {
          font-size: 13px;
          border-radius: 5px;
        }

        @media($breckpoint_ESM) {
          margin: 0 5px 0 0;
          padding: 5px 8px;
        }
      }

      .profile {
        border-radius: 50px !important;
      }

      .profile_btn_header {
        border-radius: 8px !important;

        @media($breckpoint_SM) {
          padding: 4px 10px;
        }

        @media($breckpoint_ESM) {
          padding: 3px 8px;
        }
      }

      .notifications {

        .nav {
          list-style: none;

          li {

            a {
              background-color: $primary-color;
              text-decoration: none;
              color: $light-color;
              padding: 10px 15px;
              font-size: 14px;
              font-weight: 600;
              font-family: "Sofia Sans", sans-serif;
              transition: all .4s linear;
              border-radius: 7px;

              @media($breckpoint_SM) {
                padding: 8px 12px;
              }

              @media($breckpoint_ESM) {
                padding: 7px 9px;
              }

              &:hover {
                // background-color: $header-bg-hover;
                color: $secondry-color;

                .arrow_icon {
                  transform: rotate(180deg);
                }
              }

              svg {
                @media($breckpoint_ESM) {
                  font-size: 20px;
                }
              }
            }
          }
        }

        .hover_content_public {
          display: none;
          position: absolute;
          background: $services_bg;
          min-width: 250px;
          border-radius: 0px 0px 5px 5px;
          transition: all 0.5s linear;
          animation: rotateMenu 600ms ease-in-out forwards;
          transform-origin: top center;
          z-index: 2;
          border-top: none !important;
          border: 1px solid $light-color;
          top: 51px;
          right: 10px;

          @media($breckpoint_XL) {
            top: 53px;
          }

          &:hover {
            .arrow_icon {
              transform: rotate(180deg);
            }

            .nav {
              li {
                a {
                  background-color: red;
                }
              }
            }
          }

          .drop_downcont {
            ul {
              padding: 10px;
              list-style: none;
              width: 300px;

              .hover_a {
                padding: 0px;
                transition: all .3s linear;

                .item_flex {
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                  border-radius: 4px;
                  margin: 12px 0px;
                  padding: 8px 8px;
                  background-color: transparent;
                  transition: all .3s linear;

                  .icon_gap {
                    display: flex;
                    gap: 15px;

                    span {
                      font-size: 14px;
                      font-weight: 500;
                      color: #000;
                    }

                    p {
                      margin-bottom: 0px;
                      color: #000;
                      font-size: 12px;
                      font-weight: 400;
                    }

                    svg {
                      color: $primary-color;
                      font-size: 22px;

                      @media($breckpoint_ESM) {
                        font-size: 18px;
                      }
                    }
                  }

                  .forward_icon {
                    color: transparent;
                    font-size: 18px;
                    display: block;
                    transition: all .3s linear;
                  }
                }

                &:hover {
                  .item_flex {
                    background-color: $secondry-color;
                    transition: all .3s linear;
                  }

                  .forward_icon {
                    transition: all .3s linear;
                    color: $primary-color;
                  }
                }
              }
            }
          }
        }

        .list_item {

          &:hover {
            .hover_content_public {
              display: block;
            }

            .arrow_icon {
              transform: rotate(180deg);
            }
          }
        }
      }
    }
  }
}

.mob-header {
  display: none;
  position: relative;
  z-index: 1;
  //background-color: #005ed3 !important;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);

  @media($breckpoint_LG) {
    display: block;
    position: fixed;
    width: 100%;
    z-index: 9;
  }

  .react-responsive-modal-closeButton {
    svg {
      color: #333;
    }
  }

  .inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 18px 15px;

    @media($breckpoint_LG) {
      padding: 10px 15px;
    }

    svg {
      color: #333;
      font-size: 28px;
    }

    .logo {
      img {
        height: 30px;

        @media($breckpoint_LG) {
          width: 120px !important;
          height: auto;
        }
      }

      a {
        text-decoration: none;
        color: #333;
      }
    }

    .lang {
      position: absolute;
      right: 50px;
      display: flex;
      align-items: center;

      svg {

        color: #000;
        font-size: 24px;
      }
    }

    .coin_sec {
      background-color: #000;
      border-radius: 50px;
      display: flex;
      align-items: center;
      padding-right: 10px;
      cursor: pointer;
      transition: 0.5s;
      margin-right: 10px;


      img {
        width: 26px;
        margin-right: 5px;
      }

      span {
        color: #000;
        font-size: 14px;
        font-weight: 600;

        @media($breckpoint_XL) {

          font-size: 10px;
        }

        svg {
          font-size: 18px;
        }
      }

      &:hover {
        background-color: #005ed3;
      }
    }


  }


}