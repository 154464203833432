@import '../../assets/sass/color.scss';
@import '../../assets/sass/mixin.scss';
@import '../../assets/sass/fonts.scss';

.events_info {
    flex: 0 0 40%;

    @include media-md {
        flex: 0 0 100%;
    }

    @media(max-width:797px) {
        padding-bottom: 0 !important;
        padding-top: 20px !important;
    }

    .playdate {
        @media(max-width:797px) {
            margin-bottom: 15px !important;
        }
    }

    .opportunity {
        @media(max-width:797px) {
            margin-bottom: 0 !important;
        }
    }
}

.events_image {
    flex: 0 0 50%;
    background-image: url(../../assets/img/event.jpeg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    z-index: 2;

    @media($breckpoint_MD) {
        margin: 0 15px;
    }

    &::after {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        content: '';
        background-color: rgba($dark-color, .5);
        z-index: -1;
    }

    h1 {
        color: $light-color;
    }

    @include media-md {
        flex: 0 0 100%;
    }

    .display-1 {
        @media($breckpoint_SM) {
            font-size: 35px;
        }

        @media($breckpoint_ESM) {
            font-size: 30px;
        }

        @media($breckpoint_340) {
            font-size: 27px;
        }
    }
}

.event_circle {
    width: 80px;
    height: 80px;
    border-radius: 50px;
    border: 2px solid #000;

    svg {
        font-size: 30px;
    }
}

.event_padding {
    @media(max-width:797px) {
        margin-top: 0 !important;
    }

    @media($breckpoint_SM) {
        padding-top: 0 !important;
    }
}

.sections_padding {
    .image_content_wrap {
        @media(max-width:797px) {
            display: block !important;
        }
    }

    .offer_content {
        span {
            &::after {
                @media(max-width:366px) {
                    left: calc(50% - 65px) !important;
                }
            }
        }
    }

    .babysitter {
        font-size: 44px;

        @media($breckpoint_LG) {
            font-size: 33px;
        }

        @media($breckpoint_MD) {
            font-size: 29px;
        }

        @media($breckpoint_SM) {
            font-size: 24px;
        }
    }

    .my_swiper {
        .swiper {

            .swiper-wrapper {
                display: flex;

                .swiper-slide {
                    white-space: nowrap;
                    overflow-x: hidden;
                    width: 100% !important;

                    .card {

                        .card-img {
                            img {
                                width: 100%;
                            }
                        }
                    }
                }
            }
        }
    }
}