@import "../../assets/sass/color.scss";
@import "../../assets/sass/mixin.scss";

.react-tabs__tab-list {
  display: flex;
  gap: 10px;
  margin-bottom: 15px;
  padding-bottom: 10px;
}

.react-tabs__tab {
  padding: 4px 20px;
  font-weight: 500;
  border-radius: 7px;
  cursor: pointer;
  white-space: nowrap;

  &:focus-visible {
    outline: none;
    border: none;
  }
}

.order_card {
  .react-tabs__tab--selected {
    color: $light-color;
    background: $primary-color;
  }
}

.all_order_box {
  .nany_orders {
    background-color: white;
    border: none;
    box-shadow: 0px 0px 12px -8px rgba(0, 0, 0, 0.75);
    padding: 13px;

    @media ($breckpoint_LG) {
      padding: 8px;
    }

    img {
      width: 80px;
      height: 80px;
      object-fit: cover;
      margin-right: 10px;
    }

    .card-title {
      display: flex;
      justify-content: space-between;
      margin-bottom: 5px;
      font-weight: 600;
      // font-family: "Kanit", sans-serif;
    }

    .card-img {
      margin-right: 10px;
    }

    .nanny-info {
      .location {
        color: #868686;
        font-size: 13px;

        svg {
          font-size: 20px;
        }
      }

      .time {
        color: #868686;
        font-size: 13px;
      }

      .rateing-btn {
        border: none;
        border-radius: 7px;
        background-color: $secondry-color;
        color: #636262;
        font-size: 12px;
        padding: 0px 4px;
      }

      .star {
        svg {
          color: orangered;
          font-size: 20px;
        }
      }
    }
  }

  .ordered_persons {
    padding-top: 15px;
    margin-top: 15px;
    border-top: 1px solid $primary-color;

    img {
      width: 35px;
      height: 35px;
      object-fit: cover;
      margin-right: 10px;
    }

    .nanny-small-info {
      font-weight: 500;

      h4 {
        font-weight: 500;
        font-size: 14px;
      }

      .star {
        h6 {
          font-size: 17px;

          svg {
            color: orangered;
            font-size: 16px;
          }
        }

        svg {
          color: $star-color;
        }
      }
    }

    .btn-success-nany {
      background-color: rgba($success-color, 0.1);
      color: $success-color;
      font-size: 12px;
      font-weight: 500;
      border: 1px solid rgba($success-color, 0.1);
      padding: 4px;
      border-radius: 6px;
    }
  }

  .upcoming_option {
    margin-top: 5px;
    border-top: 1px solid $primary-color;

    a {
      color: $green-color;
      font-weight: 500;
    }

    img {
      width: 20px;
      height: 100%;
    }
  }

  .btn-cancel-nany {
    background: none;
    color: $danger-color;
    font-size: 16px;
    font-weight: 500;
    border: none;
    padding: 4px;
    border-radius: 6px;
  }

  .btn-ongiing {
    background: rgba($primary-color, 0.1) !important;
    color: $primary-color;
    font-size: 16px;
    font-weight: 500;
    border: 1px solid rgba($primary-color, 0.1);
    padding: 4px;
    border-radius: 6px;
  }

  .main-button {
    background: none;
    border: none;
    color: $primary-color;
  }

  .map_btn {
    background: none !important;
    padding: 0px;
    font-size: 14px;

    svg {
      color: $primary-color;
    }
  }
}

.send_msg {
  svg {
    color: #fff;
  }
}

.googlemap {
  padding: 20px 0px !important;
}

.chat_modal {
  .chat_heading {
    img {
      width: 40px;
    }
  }

  .msg_area {
    position: relative;
    background-color: #e3e3e394;
    padding-top: 7px;

    .show_msg {
      padding: 6px 6px 22px 6px !important;
      width: 100%;
      height: 350px;
      overflow-y: scroll;

      .user_msg {
        display: flex;

        .msg {
          border-radius: 6px;
          padding: 1px 8px;
          background-color: $light-color !important;
          display: flex;
          max-width: 60%;

          .sender_name {
            font-size: 10px;
          }

          .msg_text {
            font-size: 15px;
          }
        }
      }

      .times {
        font-size: 10px;
        margin-top: 5px;
        margin-left: 5px;
        margin-right: 5px;
      }

      .sender_name {
        font-size: 13px !important;
        color: $primary-color;
        margin: 5px 0px 0px !important;
      }

      .time {
        display: flex;
        justify-content: end;
        font-size: 11px;
        margin-top: 5px;
        margin-left: 5px;
        margin-right: 5px;
      }


      .nanny_msg {
        display: flex;
        align-items: end;
        justify-content: end;

        .msg {
          border-radius: 6px;
          padding: 1px 8px;
          background-color: #fff !important;
          display: flex;
          text-align: right;
          max-width: 60%;

          .sender_name {
            font-size: 10px;
            color: $primary-color;
          }

          .msg_text {
            font-size: 15px;
            align-items: end;
          }
        }

      }
    }
  }

  .msg_send {
    position: absolute;
    bottom: 0;
    width: 100%;
    display: flex;
    background-color: #fff;
    padding: 5px;

    input {
      width: 100%;
      border: 1px solid $primary-color;
      padding: 0px 6px;
      border-radius: 20px;

      &:focus {
        outline: none;
        box-shadow: none;
      }

      &:focus-visible {
        box-shadow: none;
      }
    }

    .send_btn {
      border: none;
      background: transparent;
      color: $primary-color;
      padding: 0px 6px;

      svg {
        font-size: 28px;
      }
    }
  }

  .rateing-modal-header {
    border-bottom: 0px !important;
  }

  .rateing_stars {
    .nannyicon {
      display: flex;
      justify-content: center;

      img {
        width: 100px;
      }
    }

    p {
      text-align: center;
    }

    h5 {
      font-size: 50px;
      text-align: center;

      .rating-star-icon {
        margin: 0px 5px;
      }
    }
  }

  .submit_btn-rateing {
    background-color: $secondry-color !important;
    border: none;
    border-radius: 6px;
    color: #000;
    padding: 5px 35px;
  }


}