$primary-color: #89d6ce;
$light-color: #fff;
$dark-color: #000;
$secondry-color: #f5ec50;
$services_bg : #e5f4fb;
$testimonial_bg : #fdf1ce;
$nany-book-color : #ccc;
$star-color :#F38204;
$danger-color : #ff0000;
$success-color : #01b707;
$green-color: #89D6CE;