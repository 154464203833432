@import '../../assets/sass/color.scss';
@import '../../assets/sass/mixin.scss';

.footer_banner_bg {
    width: 100%;
    background-size: 100% !important;
    background: url('../../assets/img/footer_bg.jpg');
    z-index: 1;
    background-attachment: fixed;

    &::after {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: -1;
        content: '';
        background-color: rgba($dark-color, .5);
    }

    h5 {
        color: $light-color;
    }

    p {
        color: $light-color;
    }

    hr {
        height: 3px;
        background-color: $light-color;
    }

    ul {
        li {
            a {
                color: $light-color;
            }
        }
    }

    .contact_section {
        svg {
            font-size: 20px;
            color: $light-color;
            margin-right: 10px;
        }
    }

    @include media-md {
        background-size: cover !important;
    }
}