.stripe_card_center {
    display: flex;
    justify-content: center;

    .stripe_width {
        width: 100%;
        max-width: 600px;
    }

    form {
        padding-top: 80px;
        margin-bottom: 25px;

        .pay_button {
            text-align: center;
            margin-top: 20px;

            button {
                background-color: #89d6ce;
                font-size: 17px;
                color: #fff;
                padding: 8px 40px;
                border: none;
                border-radius: 8px;
                outline: none;
            }
        }
    }
}