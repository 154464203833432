.privacy_policy_inner {
    padding: 80px 0 20px;

    @media($breckpoint_SM) {
        padding: 70px 0 10px;
    }

    h3 {
        text-align: center;
        font-weight: 600;
        color: #89d6ce;
        margin-bottom: 25px;

        @media($breckpoint_SM) {
            margin-bottom: 15px;
        }
    }

    ol {
        li {
            margin-bottom: 8px;

            @media($breckpoint_SM) {
                font-size: 15px;
            }
        }
    }
}